function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import compose from 'lodash/flowRight';
import PropTypes from 'prop-types';
import { MiradorMenuButton } from 'mirador/dist/es/src/components/MiradorMenuButton';
import Slider from '@material-ui/core/Slider';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from '@material-ui/core/withWidth';
import { fade } from '@material-ui/core/styles/colorManipulator';

/** Styles for withStyles HOC */
var styles = function styles(_ref) {
  var _slider;

  var palette = _ref.palette,
      breakpoints = _ref.breakpoints;
  return {
    slider: (_slider = {
      backgroundColor: fade(palette.shades.main, 0.8),
      borderRadius: 25,
      top: 48,
      marginTop: 2,
      position: 'absolute',
      height: 150,
      zIndex: 100,
      marginLeft: 2,
      padding: [[2, 7, 2, 7]]
    }, _slider[breakpoints.down('sm')] = {
      top: 'auto',
      right: 48,
      width: 150,
      height: 'auto',
      marginTop: -46,
      marginBottom: 2,
      padding: [[4, 2, 4, 2]]
    }, _slider)
  };
};

var ImageTool = function (_Component) {
  _inherits(ImageTool, _Component);

  function ImageTool(props) {
    _classCallCheck(this, ImageTool);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.state = {
      open: props.open
    };
    _this.handleChange = _this.handleChange.bind(_this);
    _this.handleClick = _this.handleClick.bind(_this);
    return _this;
  }

  ImageTool.prototype.handleClick = function handleClick() {
    var _props = this.props,
        value = _props.value,
        variant = _props.variant;

    switch (variant) {
      case 'toggle':
        this.handleChange({}, value === 0 ? 100 : 0);
        break;
      default:
        this.setState(function (state) {
          return {
            open: !state.open
          };
        });
    }
  };

  ImageTool.prototype.handleChange = function handleChange(e, val) {
    var onChange = this.props.onChange;

    onChange(val);
  };

  ImageTool.prototype.render = function render() {
    var _props2 = this.props,
        children = _props2.children,
        containerId = _props2.containerId,
        label = _props2.label,
        max = _props2.max,
        min = _props2.min,
        value = _props2.value,
        type = _props2.type,
        variant = _props2.variant,
        windowId = _props2.windowId,
        foregroundColor = _props2.foregroundColor,
        classes = _props2.classes,
        width = _props2.width;
    var open = this.state.open;


    var toggled = variant === 'toggle' && value > 0;

    var id = windowId + '-' + type;

    var bubbleBg = void 0;
    if (open || toggled) {
      bubbleBg = fade(foregroundColor, open ? 0.1 : 0.25);
    }

    return React.createElement(
      'div',
      { style: { display: 'inline-block' } },
      React.createElement(
        MiradorMenuButton,
        {
          id: id + '-label',
          'aria-label': label,
          containerId: containerId,
          onClick: this.handleClick,
          'aria-expanded': open,
          'aria-controls': id,
          style: { backgroundColor: bubbleBg }
        },
        children
      ),
      open && React.createElement(
        'div',
        {
          id: id,
          'aria-labelledby': id + '-label',
          className: 'MuiPaper-elevation4 ' + classes.slider
        },
        React.createElement(Slider, {
          orientation: ['xs', 'sm'].indexOf(width) >= 0 ? 'horizontal' : 'vertical',
          min: min,
          max: max,
          value: value,
          onChange: this.handleChange
        })
      )
    );
  };

  return ImageTool;
}(Component);

ImageTool.propTypes = process.env.NODE_ENV !== "production" ? {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  containerId: PropTypes.string.isRequired,
  foregroundColor: PropTypes.string,
  label: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  variant: PropTypes.string,
  windowId: PropTypes.string.isRequired,
  width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired
} : {};

ImageTool.defaultProps = {
  foregroundColor: 'rgb(0, 0, 0)',
  min: 0,
  max: 100,
  open: false,
  variant: 'slider'
};

export default compose(withStyles(styles), withWidth())(ImageTool);