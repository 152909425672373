var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MiradorMenuButton } from 'mirador/dist/es/src/components/MiradorMenuButton';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

var ImageRotation = function (_Component) {
  _inherits(ImageRotation, _Component);

  function ImageRotation() {
    _classCallCheck(this, ImageRotation);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ImageRotation.prototype.render = function render() {
    var _props = this.props,
        label = _props.label,
        variant = _props.variant,
        otherProps = _objectWithoutProperties(_props, ['label', 'variant']);

    return React.createElement(
      MiradorMenuButton,
      _extends({
        'aria-label': label
      }, otherProps),
      variant === 'left' ? React.createElement(RotateLeftIcon, null) : React.createElement(RotateRightIcon, null)
    );
  };

  return ImageRotation;
}(Component);

export { ImageRotation as default };


ImageRotation.propTypes = process.env.NODE_ENV !== "production" ? {
  containerId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired
} : {};