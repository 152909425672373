import withTheme from '@material-ui/core/styles/withTheme';
import * as actions from 'mirador/dist/es/src/state/actions';
import { getWindowConfig, getViewer, getContainerId } from 'mirador/dist/es/src/state/selectors';
import MiradorImageTools from './MiradorImageTools';
import MiradorImageToolsMenuItem from './MiradorImageToolsMenuItem';
import translations from '../translations';

export default [{
  target: 'OpenSeadragonViewer',
  mapDispatchToProps: {
    updateWindow: actions.updateWindow,
    updateViewport: actions.updateViewport
  },
  mapStateToProps: function mapStateToProps(state, _ref) {
    var windowId = _ref.windowId;
    return {
      containerId: getContainerId(state),
      enabled: getWindowConfig(state, { windowId: windowId }).imageToolsEnabled || false,
      open: getWindowConfig(state, { windowId: windowId }).imageToolsOpen || false,
      viewConfig: getViewer(state, { windowId: windowId }) || {}
    };
  },
  mode: 'add',
  component: withTheme(MiradorImageTools),
  config: {
    translations: translations
  }
}, {
  target: 'WindowTopBarPluginMenu',
  component: MiradorImageToolsMenuItem,
  mode: 'add',
  mapDispatchToProps: {
    updateWindow: actions.updateWindow
  },
  mapStateToProps: function mapStateToProps(state, _ref2) {
    var windowId = _ref2.windowId;
    return {
      enabled: getWindowConfig(state, { windowId: windowId }).imageToolsEnabled || false
    };
  }
}];