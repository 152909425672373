var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _objectWithoutProperties(obj, keys) { var target = {}; for (var i in obj) { if (keys.indexOf(i) >= 0) continue; if (!Object.prototype.hasOwnProperty.call(obj, i)) continue; target[i] = obj[i]; } return target; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MiradorMenuButton } from 'mirador/dist/es/src/components/MiradorMenuButton';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import { fade } from '@material-ui/core/styles/colorManipulator';

var ImageFlip = function (_Component) {
  _inherits(ImageFlip, _Component);

  function ImageFlip() {
    _classCallCheck(this, ImageFlip);

    return _possibleConstructorReturn(this, _Component.apply(this, arguments));
  }

  ImageFlip.prototype.render = function render() {
    var _props = this.props,
        flipped = _props.flipped,
        label = _props.label,
        backgroundColor = _props.backgroundColor,
        foregroundColor = _props.foregroundColor,
        otherProps = _objectWithoutProperties(_props, ['flipped', 'label', 'backgroundColor', 'foregroundColor']);

    return React.createElement(
      MiradorMenuButton,
      _extends({
        'aria-label': label,
        style: { backgroundColor: flipped && fade(foregroundColor, 0.25) }
      }, otherProps),
      React.createElement(SwapHorizIcon, { style: { color: flipped && backgroundColor } })
    );
  };

  return ImageFlip;
}(Component);

export { ImageFlip as default };


ImageFlip.propTypes = process.env.NODE_ENV !== "production" ? {
  backgroundColor: PropTypes.string,
  containerId: PropTypes.string.isRequired,
  foregroundColor: PropTypes.string,
  flipped: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
} : {};

ImageFlip.defaultProps = {
  backgroundColor: 'rgb(255, 255, 255)',
  foregroundColor: 'rgb(0, 0, 0)'
};